import React from 'react';

import SubpageLayout from "../components/subpage-layout"
import Seo from "../components/seo"

import checkmark from '../images/icons/checkmark.svg'

const How = () => (
   <SubpageLayout question="Want to stay connected?" fact="Stay in the loop about future features and releases.">
      <Seo title="How" />
      <header className="subpage--content-header">
      <h1>How</h1>
      <h2>Interested in getting Intercept for your library? You've come to the right place. You aren't just signing up
        for a service – you're joining a community of libraries. Intercept was made for libraries by libraries. </h2>
      </header>
      <section>
         <div className="subpage-content-download">
         <h3>Find Intercept on Drupal.org</h3>
         <a href="https://www.drupal.org/docs/8/modules/intercept/installation-guide" target="_blank" rel="noopener noreferrer" title="Get the Code">Get the Code</a>
         </div>
         <h3>System Requirements:</h3>
         <ul>
         <li>Modern web browser (Chrome, Firefox, Edge)</li>
         <li>MariaDB 10.3+ or MySQL/Percona 5.7.8+ (recommended), PostgreSQL 10.0+, or SQLite 3.26+</li>
         <li>PHP version 7.4+ with PDO, XML, JSON, cURL, GD library, OpenSSL and Mbstring extensions</li>
         <li>64MB minimum PHP memory requirement, 256MB recommended</li>
         <li>Web server read and write access for /sites/default/files folder</li>
         <li>Apache mod_rewrite/Nginx ngx_http_rewrite_module or web server equivalent for clean urls</li>
         </ul>
         <hr />
         <h3>Getting Started is as Easy as 1, 2, 3!</h3>

         <h4>1. Add Locations, Equipment and Event Types</h4>
         <p>You’ll be guided step-by-step through setting up locations and rooms, events, room reservations and equipment.
         The interface is easy for you to understand, so you can get your rooms and events set up quickly without a steep
         learning curve. </p>

         <h4>2. Make It Your Own</h4>
         <p>Intercept is ready to use as soon as you add your locations, equipment and events. Check out an example of the
         default theme you'll get right out of the box. Add your library's logo to let your customers know they’ve come
         to the right place. Take it a step further by customizing the colors, fonts and other design elements with help
         from your in-house developer or an agency such as <a href="https://atendesigngroup.com/" target="_blank" rel="noopener noreferrer">Aten
            Design Group</a>.</p>

         <h4>3. Train Your Staff</h4>
         <p>Each staff member is assigned a role so they only see what they need to see. This keeps customer data secure,
         and prevents your staff from feeling overwhelmed. Check out our user roles and permissions tables to see what
         role is right for each of your staff members. </p>



         <div className="full-width">
         <h3>User Roles</h3>
         <table className="subpage--table">
            <thead>
               <tr>
               <th scope="col">Role</th>
               <th scope="col">Capability</th>
               <th scope="col">Use Case</th>
               </tr>
            </thead>
            <tbody>
               <tr>
               <th className="side-heading" scope="row">Admin</th>
               <td> Access to the Drupal Admin Toolbar to configure modules, content, people, appearance</td>
               <td> This role is for site owners and developers.</td>
               </tr>

               <tr>
               <th className="side-heading" scope="row">System Admin</th>
               <td>Intercept configuration: edit site appearance, manage all settings and create and edit events, access
                  to hidden fields</td>
               <td>This role is for program directors.</td>
               </tr>

               <tr>
               <th className="side-heading" scope="row">Event Manager</th>
               <td>Manage and evaluate all events in the system to improve programming, access to room and equipment
                  reservations</td>
               <td>This role is generally for branch/location managers.</td>
               </tr>
               <tr>
               <th className="side-heading" scope="row">Room Manager</th>
               <td>Manages rooms in the system. This role was added primarily to provide access to the creation of bulk room reservations.</td>
               <td>This role is for staff needing to bulk-generate room reservations.</td>
               </tr>
               <tr>
               <th className="side-heading" scope="row">Staff</th>
               <td>Help customers make room reservations and identify great events to attend, ability to print event calendars for customers at public services desks. Create and edit events, room and equipment reservations, analyze event attendance and evaluations.</td>
               <td>This role is for those who assist customers with day-to-day tasks virtually or in a location. </td>
               </tr>
               <tr>
               <th className="side-heading" scope="row">Registered Customer</th>
               <td>Can browse, register and evaluate events, ability to reserve rooms</td>
               <td>This role is for customers. </td>
               </tr>
               <tr>
               <th className="side-heading" scope="row">Kiosk</th>
               <td>Scan customers into events</td>
               <td>This role is for the kiosk customers can use to scan into events.</td>
               </tr>
            </tbody>
         </table>
         </div>


         <div className="full-width">
            <h3>Key Features</h3>
            <table className="subpage--table">
               <thead>
                  <tr>
                     <th scope="col">Feature</th>
                     <th scope="col">Admin</th>
                     <th scope="col">System Admin</th>
                     <th scope="col">Event Manager</th>
                     <th scope="col">Staff</th>
                     <th scope="col">Room Manager</th>
                     <th scope="col">Registered Customer</th>
                     <th scope="col">Kiosk</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <th className="side-heading" scope="row">Scan in customers for events</th>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                     <td></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                  </tr>
                  <tr>
                     <th className="side-heading" scope="row">
                     Create and update room reservations
                     </th>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                  </tr>
                  <tr>
                     <th className="side-heading" scope="row">
                     Analyze event attendance numbers with population segmentation
                     </th>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                     <td></td>
                     <td></td>
                  </tr>
                  <tr>
                     <th className="side-heading" scope="row">Access customer evaluations</th>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                     <td></td>
                     <td></td>
                  </tr>
                  <tr>
                     <th className="side-heading" scope="row">
                     Create one-time events and recurring events from event templates in
                     calendar
                     </th>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                     <td></td>
                     <td></td>
                  </tr>
                  <tr>
                     <th className="side-heading" scope="row">Reserve equipment</th>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                     <td></td>
                     <td></td>
                  </tr>

                  <tr>
                     <th className="side-heading" scope="row">Download customer data</th>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                  </tr>
                  <tr>
                     <th className="side-heading" scope="row">Create and update bulk room reservations</th>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                     <td></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                     <td></td>
                  </tr>
                  <tr>
                     <th className="side-heading" scope="row">Customize system settings</th>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                  </tr>
                  <tr>
                     <th className="side-heading" scope="row">
                     Add and edit room and equipment for reservations
                     </th>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td><img src={checkmark} alt="checkmark" /></td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                     <td></td>
                  </tr>
               </tbody>
            </table>

         </div>

         <p>Once you determine which permissions your staff members individually need, you can get them enrolled in the
         Intercept system.</p>

         <p>Intercept is designed to help your programming evolve. This means less time on annoying tasks for staff and
         easier event browsing and reservations for customers.</p>

         <p>Your staff and customers will love how painless the interface is. Each staff member’s admin dashboard has all
         of the tasks they need with simple navigation to find specific task areas around the site, and each customer’s
         dashboard has recommended events to give them more of what they love. Staff members can even follow up on recent
         programming with staff-only notes to use for future events.</p>

         <h4>API Methods Included in Intercept</h4>

         <p>If you're planning to use Intercept to drive data to another source such as a mobile app or digital signage, you may want to utilize either the RSS feeds or the API endpoints/methods that are included as part of Intercept. All of the available endpoints are documented more thoroughly in the Intercept README.md file but be aware that only a few are publicly accessible for security reasons. The rest are currently only available for use within the application itself.</p>

         <p>Since Drupal core now includes <a href="https://jsonapi.org">JSON:API</a> for queries related to content, queries for information about events, rooms, and locations (all of which are standard Drupal content "nodes") can all be built using the JSON:API standard syntax.</p>

         <p>Some of the public API methods that you might find most useful for these types of apps are:</p>
         <ul>
            <li>List of events</li>
            <li>List of rooms</li>
            <li>Room availability</li>
         </ul>


      </section>
   </SubpageLayout>

)

export default How